<template>
<!-- Admin dashboard -->
<div class="dashboard-container" v-if="$can('any product edit', 'all')">
    <account-role-title role="Admin" />
    <v-row>
        <v-col cols="12" md="4" sm="6" order="1" class="align-self-start">
            <MpProfit class="mb-6" />
            <Sales :admin="true" />
        </v-col>
        <v-col cols="12" md="4" sm="6" order="2" class="align-self-start">
            <Balance :admin="true" class="mb-6" />
            <Users />
        </v-col>
        <v-col cols="12" md="4" sm="12" order="3" class="align-self-start">
            <Inventory :admin="true" />
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" md="4" sm="6" order="1" class="align-self-start">
            <div class="d-flex">
                <Orders class="mr-6" :admin="true" />
                <PendingPayouts :admin="true" />
            </div>
        </v-col>
        <v-col cols="12" md="8" sm="12" order="2" class="align-self-start">
            <Refunds :admin="true" />
        </v-col>
    </v-row>
</div>
</template>

<script>
import MpProfit from "@/views/cabinet/dashboard/blocks/MpProfit";
import Balance from "@/views/cabinet/dashboard/blocks/Balance";
import Users from "@/views/cabinet/dashboard/blocks/Users";
import Orders from "@/views/cabinet/dashboard/blocks/Orders";
import PendingPayouts from "@/views/cabinet/dashboard/blocks/PendingPayouts";
import Refunds from "@/views/cabinet/dashboard/blocks/Refunds";
import Sales from "@/views/cabinet/dashboard/blocks/Sales";
import Inventory from "@/views/cabinet/dashboard/blocks/Inventory";

export default {
    name: "Dashboard",
    components: {
        PendingPayouts,
        Orders,
        Refunds,
        MpProfit,
        Balance,
        Users,
        Sales,
        Inventory
    }
}
</script>
