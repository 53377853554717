<template>
<v-card height="425" class="rounded-lg inventory" tile>
    <v-card-title class="align-start">
        {{ $t("Inventory") }}
        <v-spacer></v-spacer>
        <v-btn small icon class="me-n3 mt-n1" :to="to">
            <!-- Arrow -->
            <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A" />
            </svg>
        </v-btn>
    </v-card-title>
    <v-list-item v-for="(info, index) in productsInfo" :key="index">
        <v-list-item-content class="mt-1">
            <v-list-item-title class="dashboard-list-item">
                <div>
                    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_ddd_2883_125612)">
                            <rect x="3" y="2" width="40" height="40" rx="20" :fill="info.color" />
                            <path d="M29 16H27C27 13.79 25.21 12 23 12C20.79 12 19 13.79 19 16H17C15.9 16 15 16.9 15 18V30C15 31.1 15.9 32 17 32H29C30.1 32 31 31.1 31 30V18C31 16.9 30.1 16 29 16ZM21 20C21 20.55 20.55 21 20 21C19.45 21 19 20.55 19 20V18H21V20ZM23 14C24.1 14 25 14.9 25 16H21C21 14.9 21.9 14 23 14ZM27 20C27 20.55 26.55 21 26 21C25.45 21 25 20.55 25 20V18H27V20Z" fill="white" />
                        </g>
                        <defs>
                            <filter id="filter0_ddd_2883_125612" x="0" y="0" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="1" />
                                <feGaussianBlur stdDeviation="1.5" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.12 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2883_125612" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="1" />
                                <feGaussianBlur stdDeviation="0.5" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.14 0" />
                                <feBlend mode="normal" in2="effect1_dropShadow_2883_125612" result="effect2_dropShadow_2883_125612" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_2883_125612" />
                                <feOffset dy="2" />
                                <feGaussianBlur stdDeviation="0.5" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
                                <feBlend mode="normal" in2="effect2_dropShadow_2883_125612" result="effect3_dropShadow_2883_125612" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2883_125612" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                    <span>{{ $t(info.title) }}</span>
                </div>
                <div class="dashboard-list-price">{{ info.count }}</div>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</v-card>
</template>

<script>
import store from '@/store'
import {
    onMounted,
    computed
} from "@vue/composition-api";
import {
    useVModel
} from '@vueuse/core'

export default {
    name: 'Inventory',
    props: {
        admin: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {
        emit
    }) {
        const adminData = useVModel(props, 'admin', emit)
        onMounted(() => {
            !adminData.value ? store.dispatch('product/getProductsShortInfo') : store.dispatch('product/getAdminProductsShortInfo')
        })
        return {
            to: computed(() => {
                if (adminData.value) {
                    return {
                        name: 'admin-products-list',
                        params: {
                            tab: 'all'
                        }
                    }
                }
                return {
                    name: 'seller-products',
                    params: {
                        tab: 'all'
                    }
                }
            }),
            productsInfo: computed(() => store.state.product.productsShortInfo),
        }
    }
}
</script>
