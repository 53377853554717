<template>
    <v-card v-if="admin" min-height="150" tile class="d-flex justify-space-between rounded-lg balance" style="padding: 0, 45px, 0, 0">
        <v-card-title class="d-flex flex-column align-baseline justify-space-between"style="width: 100%" >
            <div class="dashboard-block-title">{{ $t('Balance') }}</div>
            <div clas="d-flex align-baseline justify-space-between">
                <span class="balance-title">{{ $t('Seller’s') }}</span>
                <span class="balance-value admin ml-4">${{ sellersBalance }}</span>
            </div>
            <div clas="d-flex align-baseline justify-space-between">
                <span class="balance-title">{{ $t('Buyer’s') }}</span>
                <span class="balance-value admin ml-4">${{ usersBalance }}</span>
            </div>
        </v-card-title>
        <div style="align-self: end">
            <v-img :width="90" :src="avatar"></v-img>
        </div>
    </v-card>
    <v-card v-else min-height="150" tile class="d-flex justify-space-between rounded-lg balance" style="padding: 0, 45px, 0, 0">
        <v-card-title class="d-flex flex-column align-baseline justify-space-between">
            <span class="text-black">{{ $t('Balance') }}</span>
            <span class="balance-value mb-4">${{ balance }}</span>
        </v-card-title>
        <div style="align-self: end">
            <v-img :width="90" :src="avatar"></v-img>
        </div>
    </v-card>
</template>

<script>
import {
    ref,
    computed,
    onMounted
} from "@vue/composition-api"
import {
    useVModel
} from '@vueuse/core'
import store from "@/store"
import axios from "@axios";
import { mdiReplay } from '@mdi/js';

export default {
    name: "Balance",
    props: {
        admin: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {
        emit
    }) {
        const sellersBalance = ref('-')
        const usersBalance = ref('-')
        const adminData = useVModel(props, 'admin', emit)
        const getSellersBalance = () => {
            axios.get('admin/dashboard/sellers-and-users-balance').then(res => {
                sellersBalance.value = res.data.sellers
                usersBalance.value = res.data.users
            }).catch(e => console.log(e))
        }
        onMounted(() => {
            if(adminData.value) {
                getSellersBalance()
            }
        })
        return {
            balance: computed(() => store.state.market.balance),
            usersBalance,
            sellersBalance,
            avatar: require('@/assets/images/avatars/human_coffe.png'),
            icons: {
                mdiReplay
            }
        }
    }
}
</script>
