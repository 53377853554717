<template>
<v-card min-height="150" tile class="d-flex justify-space-between rounded-lg balance" style="padding: 0, 45px, 0, 0">
    <v-card-title class="d-flex flex-column align-baseline justify-space-between">
        <div class="dashboard-block-title">{{ $t('MP Profit') }}</div>
        <span class="balance-value mb-4">${{ mpProfit }}</span>
    </v-card-title>
    <div style="align-self: end">
        <v-img :width="125" :src="avatar"></v-img>
    </div>
</v-card>
</template>

<script>
import {ref} from "@vue/composition-api";
import axios from "@axios";
export default {
    name: "MpProfit",
    setup() {
      const mpProfit = ref('-')
      axios.get('admin/dashboard/mp-profit').then(res => mpProfit.value = res.data)
        return {
            mpProfit,
            avatar: require('@/assets/images/avatars/human_notebook.png'),
        }
    }
}
</script>
