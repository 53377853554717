<template>
<v-card height="250" class="users">
    <v-card-title class="align-start">
        {{ $t("Users") }}
        <v-spacer></v-spacer>
        <v-btn small icon class="me-n3 mt-n1" :to="{name: 'users', params: {tab: 'all'}}">
            <!-- Arrow -->
            <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A" />
            </svg>
        </v-btn>
    </v-card-title>
    <v-card-text class="text-no-wrap text--primary mt-3">
        <div class="d-flex mb-3" style="justify-content: center">
            <div class="d-flex align-center ml-4" style="width: 200px">
                <router-link :to="{name: 'users', params: {tab: 'all'}}">
                    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_ddd_2877_128109)">
                            <rect x="3" y="2" width="40" height="40" rx="5" fill="#9155FD" />
                            <path d="M23 15.9C24.16 15.9 25.1 16.84 25.1 18C25.1 19.16 24.16 20.1 23 20.1C21.84 20.1 20.9 19.16 20.9 18C20.9 16.84 21.84 15.9 23 15.9ZM23 24.9C25.97 24.9 29.1 26.36 29.1 27V28.1H16.9V27C16.9 26.36 20.03 24.9 23 24.9ZM23 14C20.79 14 19 15.79 19 18C19 20.21 20.79 22 23 22C25.21 22 27 20.21 27 18C27 15.79 25.21 14 23 14ZM23 23C20.33 23 15 24.34 15 27V30H31V27C31 24.34 25.67 23 23 23Z" fill="white" />
                        </g>
                        <defs>
                            <filter id="filter0_ddd_2877_128109" x="0" y="0" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="1" />
                                <feGaussianBlur stdDeviation="1.5" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.12 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2877_128109" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="1" />
                                <feGaussianBlur stdDeviation="0.5" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.14 0" />
                                <feBlend mode="normal" in2="effect1_dropShadow_2877_128109" result="effect2_dropShadow_2877_128109" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_2877_128109" />
                                <feOffset dy="2" />
                                <feGaussianBlur stdDeviation="0.5" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.2 0" />
                                <feBlend mode="normal" in2="effect2_dropShadow_2877_128109" result="effect3_dropShadow_2877_128109" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2877_128109" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                </router-link>
                <div class="d-flex flex-column ml-4">
                    <span class="user-title">{{ $t("Total Users") }}</span>
                    <span class="user-value mt-2">{{ usersInfo.all.count }}</span>
                </div>
            </div>
            <div class="d-flex align-center ml-4" style="width: 200px">
                <router-link :to="{name: 'users', params: {tab: 'moderators'}}">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="20" fill="#FFE0E0" fill-opacity="0.88" />
                        <path d="M20 22C16.6863 22 14 19.3137 14 16C14 12.6863 16.6863 10 20 10C23.3137 10 26 12.6863 26 16C25.9964 19.3122 23.3122 21.9964 20 22Z" fill="#FF4C51" />
                        <path d="M23.7227 21.0003C22.6968 21.7776 21.4063 22.2457 20 22.2472C18.5918 22.2472 17.2993 21.7788 16.2725 21C12.9399 22.2848 10.4302 25.2488 10.006 28.9258C9.94593 29.4579 10.3448 29.9361 10.897 29.9943L28.9929 30C29.0297 30 29.0664 29.9981 29.103 29.9943C29.6552 29.9361 30.0541 29.4579 29.994 28.9258C29.5761 25.3041 27.1053 22.2992 23.7227 21.0003Z" fill="#FF4C51" />
                    </svg>
                </router-link>
                <div class="d-flex flex-column ml-4">
                    <span class="user-title">{{ $t("Moderators") }}</span>
                    <span class="user-value mt-2">{{ usersInfo.moderatorsAndAdmins.count }}</span>
                </div>
            </div>
        </div>
        <div class="d-flex" style="justify-content: center">
            <div class="d-flex align-center ml-4" style="width: 200px">
                <router-link :to="{name: 'users', params: {tab: 'sellers'}}" class="pl-1">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="20" fill="#4CAF50" />
                        <rect width="40" height="40" rx="20" fill="white" fill-opacity="0.88" />
                        <path d="M20 22C16.6863 22 14 19.3137 14 16C14 12.6863 16.6863 10 20 10C23.3137 10 26 12.6863 26 16C25.9964 19.3122 23.3122 21.9964 20 22Z" fill="#A7E37A" />
                        <path d="M23.7227 21.0003C22.6968 21.7776 21.4063 22.2457 20 22.2472C18.5918 22.2472 17.2993 21.7788 16.2725 21C12.9399 22.2848 10.4302 25.2488 10.006 28.9258C9.94593 29.4579 10.3448 29.9361 10.897 29.9943L28.9929 30C29.0297 30 29.0664 29.9981 29.103 29.9943C29.6552 29.9361 30.0541 29.4579 29.994 28.9258C29.5761 25.3041 27.1053 22.2992 23.7227 21.0003Z" fill="#56CA00" />
                    </svg>
                </router-link>
                <div class="d-flex flex-column ml-4">
                    <span class="user-title">{{ $t("Sellers") }}</span>
                    <span class="user-value mt-2">{{ usersInfo.sellers.count }}</span>
                </div>
            </div>
            <div class="d-flex align-center ml-4" style="width: 200px">
                <router-link :to="{name: 'users', params: {tab: 'buyers'}}">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="20" fill="#4CAF50" />
                        <rect width="40" height="40" rx="20" fill="#FDEDE1" />
                        <path d="M20 22C16.6863 22 14 19.3137 14 16C14 12.6863 16.6863 10 20 10C23.3137 10 26 12.6863 26 16C25.9964 19.3122 23.3122 21.9964 20 22Z" fill="#FFCF5C" />
                        <path d="M23.7227 21.0003C22.6968 21.7776 21.4063 22.2457 20 22.2472C18.5918 22.2472 17.2993 21.7788 16.2725 21C12.9399 22.2848 10.4302 25.2488 10.006 28.9258C9.94593 29.4579 10.3448 29.9361 10.897 29.9943L28.9929 30C29.0297 30 29.0664 29.9981 29.103 29.9943C29.6552 29.9361 30.0541 29.4579 29.994 28.9258C29.5761 25.3041 27.1053 22.2992 23.7227 21.0003Z" fill="#FFB400" />
                    </svg>
                </router-link>
                <div class="d-flex flex-column ml-4">
                    <span class="user-title">{{ $t("Buyers") }}</span>
                    <span class="user-value mt-2">{{ usersInfo.customers.count }}</span>
                </div>
            </div>
        </div>
    </v-card-text>
</v-card>
</template>

<script>
import store from '@/store'
import {
    onMounted,
    computed
} from "@vue/composition-api";

export default {
    setup() {
        onMounted(() => {
            store.dispatch('user/getUsersShortInfo')
        })

        return {
            usersInfo: computed(() => store.state.user.usersShortInfo),
        }
    }
}
</script>
