<template>
<!-- Admin Pending Payouts -->
<statistics-card-vertical v-if="admin" :to="{ name: 'admin-payouts', params: { tab: 'pending' } }" :color="amdinPendingPayout.color" :icon="amdinPendingPayout.icon" :statistics="statisticPayouts" :stat-title="amdinPendingPayout.statTitle"></statistics-card-vertical>
<!-- Seller Pending Payouts -->
<statistics-card-vertical v-else :to="{ name: 'store-transactions', params: { tab: 'payouts' } }" :seller="true" :color="pendingPayout.color" :icon="pendingPayout.icon" :statistics="statisticPayouts" :stat-title="pendingPayout.statTitle" :subtitle="pendingPayout.subtitle"></statistics-card-vertical>
</template>

<script>
import {
    mdiPoll,
    mdiCurrencyUsd
} from '@mdi/js'
import {
    useVModel
} from '@vueuse/core'
import {
    onMounted,
    computed
} from "@vue/composition-api"
import store from '@/store'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

export default {
    name: 'PendingPayouts',
    props: {
        admin: {
            type: Boolean,
            default: false
        }
    },
    components: {
        StatisticsCardVertical
    },
    setup(props, {
        emit
    }) {
        const adminData = useVModel(props, 'admin', emit)
        onMounted(() => {
            adminData.value ? store.dispatch('payouts/getAdminPayoutsDashData') : store.dispatch('payouts/getSellerPayoutsDashData')
        })
        const amdinPendingPayout = {
            statTitle: 'Pending Payouts',
            icon: mdiPoll,
            color: 'error',
        }
        const pendingPayout = {
            statTitle: 'Pending Payouts',
            icon: mdiCurrencyUsd,
            color: '#FFF1F1',
            subtitle: 'Calculated in last 7 days',
        }
        return {
            amdinPendingPayout,
            pendingPayout,
            statisticPayouts: computed(() => '$' + store.state.payouts.statisticPayouts.toString()),
        }
    }
}
</script>
