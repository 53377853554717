<template>
<v-card height="250" class="sales">
    <v-card-title style="padding-bottom: 10px">
        <div class="d-flex mb-3" style="width: 100%">
            {{ $t("Sales") }}
            <v-spacer></v-spacer>
            <v-btn small icon class="me-n3 mt-n1" :to="to">
                <!-- Arrow -->
                <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.378906 13.0463L5.91307 7.5L0.378906 1.95375L2.08266 0.25L9.33266 7.5L2.08266 14.75L0.378906 13.0463Z" fill="#544F5A" />
                </svg>
            </v-btn>
        </div>
        <div class="d-flex justify-space-between" style="width: 100%">
            <div class="d-flex flex-column" style="min-width: 150px">
                <span class="sales-value">${{ sales.total || '-' }}</span>
                <span class="mt-4">{{ $t("Total Sales") }}</span>
            </div>
            <div class="chart-wrapper mr-4" style="max-width: 165px">
                <vue-apex-charts :options="chartOptions" :series="series"></vue-apex-charts>
            </div>
        </div>
    </v-card-title>
    <v-card-text class="text-no-wrap text--primary d-flex" style="padding: 0 25px 0 10px">
        <v-list-item style="padding: 0">
            <v-list-item-content style="padding-top: 0">
                <div class="d-flex justify-space-between">
                    <div class="d-flex align-center">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_2876_127949)">
                                <rect x="10" y="8" width="40" height="40" rx="20" fill="#EAF5EA" />
                                <path d="M29.8003 26.9C27.5303 26.31 26.8003 25.7 26.8003 24.75C26.8003 23.66 27.8103 22.9 29.5003 22.9C31.2803 22.9 31.9403 23.75 32.0003 25H34.2103C34.1403 23.28 33.0903 21.7 31.0003 21.19V19H28.0003V21.16C26.0603 21.58 24.5003 22.84 24.5003 24.77C24.5003 27.08 26.4103 28.23 29.2003 28.9C31.7003 29.5 32.2003 30.38 32.2003 31.31C32.2003 32 31.7103 33.1 29.5003 33.1C27.4403 33.1 26.6303 32.18 26.5203 31H24.3203C24.4403 33.19 26.0803 34.42 28.0003 34.83V37H31.0003V34.85C32.9503 34.48 34.5003 33.35 34.5003 31.3C34.5003 28.46 32.0703 27.49 29.8003 26.9Z" fill="#56CA00" />
                            </g>
                            <defs>
                                <filter id="filter0_d_2876_127949" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="2" />
                                    <feGaussianBlur stdDeviation="5" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2876_127949" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2876_127949" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                        <div class="d-flex flex-column">
                            <span style="font-size: 16px; font-weight: 600">${{ sales.lastWeek || '-' }}</span>
                            <span style="font-size: 12px" class="mt-2">{{ $t("For the last week")}}</span>
                        </div>
                    </div>
                    <div class="d-flex align-center">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_2876_127949)">
                                <rect x="10" y="8" width="40" height="40" rx="20" fill="#EAF5EA" />
                                <path d="M29.8003 26.9C27.5303 26.31 26.8003 25.7 26.8003 24.75C26.8003 23.66 27.8103 22.9 29.5003 22.9C31.2803 22.9 31.9403 23.75 32.0003 25H34.2103C34.1403 23.28 33.0903 21.7 31.0003 21.19V19H28.0003V21.16C26.0603 21.58 24.5003 22.84 24.5003 24.77C24.5003 27.08 26.4103 28.23 29.2003 28.9C31.7003 29.5 32.2003 30.38 32.2003 31.31C32.2003 32 31.7103 33.1 29.5003 33.1C27.4403 33.1 26.6303 32.18 26.5203 31H24.3203C24.4403 33.19 26.0803 34.42 28.0003 34.83V37H31.0003V34.85C32.9503 34.48 34.5003 33.35 34.5003 31.3C34.5003 28.46 32.0703 27.49 29.8003 26.9Z" fill="#56CA00" />
                            </g>
                            <defs>
                                <filter id="filter0_d_2876_127949" x="0" y="0" width="60" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="2" />
                                    <feGaussianBlur stdDeviation="5" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.227451 0 0 0 0 0.207843 0 0 0 0 0.254902 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2876_127949" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2876_127949" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                        <div class="d-flex flex-column">
                            <span style="font-size: 16px; font-weight: 600">${{ sales.today || '-' }}</span>
                            <span style="font-size: 12px" class="mt-2">{{ $t("Today")}}</span>
                        </div>
                    </div>
                </div>
            </v-list-item-content>
        </v-list-item>
    </v-card-text>
</v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import store from '@/store'
import {
    getVuetify
} from '@core/utils'
import {
    computed,
    onMounted
} from "@vue/composition-api";
import {
    useVModel
} from '@vueuse/core'

export default {
    name: 'Sales',
    props: {
        admin: {
            type: Boolean,
            default: false
        }
    },
    components: {
        VueApexCharts,
    },
    setup(props, {
        emit
    }) {
        const adminData = useVModel(props, 'admin', emit)
        onMounted(() => {
            adminData.value ? store.dispatch('order/getAdminSalesDash') : store.dispatch('order/getSellerSalesDash')
        })
        const $vuetify = getVuetify()
        const chartOptions = {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false,
                },
                sparkline: {
                    enabled: true,
                },
            },
            grid: {
                show: false,
            },
            colors: [$vuetify.theme.currentTheme.primary],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '30%',
                    startingShape: 'rounded',
                    endingShape: 'rounded',
                    borderRadius: 4,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                show: false,
            },
            tooltip: {
                x: {
                    show: false,
                },
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                    plotOptions: {
                        bar: {
                            horizontal: false
                        }
                    },
                    legend: {
                            position: "bottom"
                        }
                    }
                }
            ]
        }
        return {
            sales: computed(() => store.state.order.sales),
            to: computed(() => {
                if (adminData.value) {
                    return {
                        name: 'admin-transactions',
                        params: {
                            tab: 'all'
                        }
                    }
                }
                return {
                    name: 'store-transactions',
                    params: {
                        tab: 'all'
                    }
                }
            }),
            chartOptions,
            series: [{
                name: '2023',
                data: [50, 40, 120, 80, 40, 60, 40],
            }]
        }
    }
}
</script>
